@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css");
html, body {
  min-height: 100%;
  height: 100%;
}

.transition-300, offline #offline-msg, event-details-view .event-gallery .event-photo .destroy-btn, event-details-view .event-gallery .event-photo.event-photo-new .back-img, post-details-view .event-gallery .event-photo .destroy-btn, post-details-view .event-gallery .event-photo.event-photo-new .back-img {
  transition: all 300ms ease !important;
}

.transition-1000 {
  transition: all 1s ease;
}

.text-accent {
  color: #006C94;
}

.text-warning {
  color: #ff6400;
}

.text-success {
  color: #00e600;
}

.text-danger {
  color: #ff6666;
}

.text-sm {
  font-size: 0.7em;
}

.text-fade {
  opacity: 0.5;
}

.text-center {
  text-align: center;
}

.no-bg {
  background: transparent;
}

.no-margin {
  margin: 0;
}

.bg-cover {
  background-size: cover;
  background-position: center center;
}

.bg-danger {
  background: rgba(255, 0, 0, 0.1);
}

.bg-success {
  background: rgba(0, 128, 0, 0.1);
}

.bg-warning {
  background-color: rgba(255, 165, 0, 0.1);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

.wrapper {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 15px;
}

@media (min-width: 960px) {
  .wrapper {
    padding: 40px;
  }
}

.full-page {
  position: relative;
  min-height: 100%;
}

.full-page .full-page-content {
  position: relative;
  z-index: 10;
}

.full-page .full-page-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
}

.full-page .full-page-background:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: ' ';
  background-color: #222;
  opacity: 0.85;
}

.avatar {
  max-width: 60px;
  max-height: 60px;
  border-radius: 600px;
}

.avatar-sm {
  max-width: 40px;
  max-height: 40px;
  border-radius: 40px;
}

.avatar-xs {
  max-width: 30px;
  max-height: 30px;
  border-radius: 30px;
}

.avatar-big {
  max-width: 100px;
  max-height: 100px;
  border-radius: 100px;
}

a, a:active, a:visited {
  color: #006C94;
  text-decoration: none;
}

.padding {
  padding: 20px;
}

#loading-bar .bar {
  background: #006C94;
}

#loading-bar-spinner .spinner-icon {
  border-top-color: #006C94 !important;
  border-left-color: #006C94 !important;
  color: #006C94 !important;
}

.text-accent {
  color: #006C94;
}

.text-warning {
  color: #ff6400;
}

.text-success {
  color: #00e600;
}

.text-danger {
  color: #ff6666;
}

.text-fade {
  opacity: 0.5;
}

.text-center {
  text-align: center;
}

.text-deprecated {
  text-decoration: line-through;
}

offline {
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

offline #offline-msg {
  background: #222;
  padding: 20px;
  margin-top: -100px;
  letter-spacing: 1px;
  font-size: 18px;
}

offline #offline-msg, offline #offline-msg md-icon {
  color: #ff9999;
}

offline #offline-msg.active {
  margin-top: 0;
}

.md-sidenav-left {
  border-right: solid 1px #ddd;
}

.md-sidenav-left h1 {
  font-weight: 300;
  margin-bottom: -5px;
  text-transform: uppercase;
  color: #006C94;
}

.md-sidenav-left h3 {
  margin-top: 0;
  font-weight: 300;
  color: #006C94;
}

.md-sidenav-left md-list {
  border-top: solid 1px #ddd;
}

.md-sidenav-left .auth-area {
  background-color: transparent;
  border-top: solid 1px #ddd;
  color: inherit !important;
}

.md-sidenav-left .auth-area * {
  color: inherit !important;
}

.md-sidenav-left #menu-list .active a {
  background-color: rgba(0, 108, 148, 0.3);
}

md-toolbar {
  background-color: rgba(239, 239, 239, 0.5) !important;
  color: #666 !important;
}

md-toolbar md-icon {
  color: #666 !important;
}

endpoint-crud-view {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

endpoint-crud-view .layout .items-content-card md-card-content {
  overflow: auto;
}

endpoint-crud-view .layout .filters-column {
  width: 250px;
  padding-top: 80px;
}

endpoint-crud-view .layout .filters-column .md-undefined-select label {
  -webkit-transform: translate3d(0, 28px, 0) scale(1) !important;
          transform: translate3d(0, 28px, 0) scale(1) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

endpoint-crud-view .data-table {
  width: 100%;
}

endpoint-crud-view .data-table td, endpoint-crud-view .data-table th {
  text-align: right;
  padding: 5px;
}

endpoint-crud-view .data-table td:first-child, endpoint-crud-view .data-table th:first-child {
  padding-left: 0;
}

endpoint-crud-view .data-table td:last-child, endpoint-crud-view .data-table th:last-child {
  padding-right: 0;
}

endpoint-crud-view .data-table td:first-child, endpoint-crud-view .data-table th:first-child {
  text-align: left;
}

endpoint-crud-view .data-table td:first-child .data-text-display, endpoint-crud-view .data-table th:first-child .data-text-display {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

endpoint-crud-view .data-table th {
  text-transform: uppercase;
  font-weight: normal;
}

endpoint-crud-view .data-table th a {
  cursor: pointer;
  position: relative;
  padding-left: 15px;
}

endpoint-crud-view .data-table th a md-icon {
  position: absolute;
  left: 0;
  top: 3px;
  font-size: 14px;
  color: inherit;
  height: auto;
  min-height: 0;
  width: auto;
  min-width: 0;
}

endpoint-crud-view .data-table td {
  border-bottom: solid 1px #eee;
}

endpoint-crud-view .data-table td .data-text-display {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  min-height: 40px;
  font-size: 14px;
  color: #666;
}

endpoint-crud-view .data-table td tr:last-child td {
  border-bottom: 0;
}

event-details-view {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

event-details-view .md-card-content {
  overflow: auto;
}

event-details-view form {
  padding: 20px;
}

event-details-view .event-gallery {
  margin: 150px 0;
}

event-details-view .event-gallery h1 {
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 40px;
}

event-details-view .event-gallery .event-photo {
  position: relative;
}

event-details-view .event-gallery .event-photo .destroy-btn {
  opacity: 0;
  position: absolute;
  bottom: 30px;
  left: 50%;
  margin-left: -10px;
}

event-details-view .event-gallery .event-photo .destroy-btn md-icon {
  color: white !important;
}

event-details-view .event-gallery .event-photo:hover .destroy-btn {
  opacity: 1;
}

event-details-view .event-gallery .event-photo .back-img {
  margin: 1px;
  height: 220px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

event-details-view .event-gallery .event-photo.event-photo-new .back-img {
  border: dashed 2px #ccc;
  cursor: pointer;
  color: #bbb;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
}

event-details-view .event-gallery .event-photo.event-photo-new .back-img .error {
  font-size: 12px;
  color: #ff3333;
  text-transform: none;
  letter-spacing: 0;
  font-weight: bold;
  opacity: 0.7;
  padding-top: 10px;
}

event-details-view .event-gallery .event-photo.event-photo-new .back-img:hover {
  background-color: #efefef;
  color: #aaa;
}

post-details-view {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

post-details-view .md-card-content {
  overflow: auto;
}

post-details-view form {
  padding: 20px;
}

post-details-view .event-gallery {
  margin: 150px 0;
}

post-details-view .event-gallery h1 {
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 40px;
}

post-details-view .event-gallery .event-photo {
  position: relative;
}

post-details-view .event-gallery .event-photo .destroy-btn {
  opacity: 0;
  position: absolute;
  bottom: 30px;
  left: 50%;
  margin-left: -10px;
}

post-details-view .event-gallery .event-photo .destroy-btn md-icon {
  color: white !important;
}

post-details-view .event-gallery .event-photo:hover .destroy-btn {
  opacity: 1;
}

post-details-view .event-gallery .event-photo .back-img {
  margin: 1px;
  height: 220px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

post-details-view .event-gallery .event-photo.event-photo-new .back-img {
  border: dashed 2px #ccc;
  cursor: pointer;
  color: #bbb;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
}

post-details-view .event-gallery .event-photo.event-photo-new .back-img .error {
  font-size: 12px;
  color: #ff3333;
  text-transform: none;
  letter-spacing: 0;
  font-weight: bold;
  opacity: 0.7;
  padding-top: 10px;
}

post-details-view .event-gallery .event-photo.event-photo-new .back-img:hover {
  background-color: #efefef;
  color: #aaa;
}

